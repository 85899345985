export enum NavigationUrl {
  Home = '/',
  ServiceBuyStorage = '/service_buy_storage',
  RefreshCertificate = '/service_refresh_certificate',
  SearchCertificates = '/service_search_certificate',
  Sign = '/service_signature_certificate',
  CheckSign = '/service_check_certificate',
  ChangeStatus = '/change-status',
  Device = '/devices',
  Partners = '/become_office',
  News = '/news',
  Contacts = '/contacts',
  About = '/about',
  Contract = '/contract',
  Statement = '/statement',
  DiiaProject = '/diia-project',
  DiiaEng = '/diia-eng',
  Software = '/software',
  Reglament = '/reglament',
  Keys = '/keys',
  Faq = '/faq',
  Partnerships = '/partnerships',
  Contracts = '/contracts',
  DigitalSignature = '/digital-signature',
  Login = '/login',
  DiiaSign = '/diia-sign',
}
